<template>
  <agencies-table
    :headers="headers"
    :agencies="agencies"
    @getid="delete_Agency($event)"
    @triggerPageChange="readPaginatedAgencies($event)"
  />
</template>

<script>
import AgenciesTable from "../../../components/Tables/AgenciesTable.vue";

export default {
  data: () => ({
    headers: [
      {
        text: "Agency Name",
        align: "start",
        sortable: false,
        value: "agency_name",
        width: "300px",
      },
      { text: "Contact", value: "contact_email", width: "250px" },
      { text: "Clients", value: "client", width: "250px" },
      {
        value: "actions",
        width: "130px",
        align: "end",
      },
    ],
  }),
  components: {
    AgenciesTable,
  },
  async mounted() {
    await this.readAgencies();
  },
  computed: {
    agencies() {
      return this.$store.state.agencies;
    },
  },
  methods: {
    async delete_Agency(id) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("deleteAgency", id);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "agency deleted successfully!",
          group: "success",
        });
        await this.readAgencies();
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async readAgencies() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readAgencies");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async readPaginatedAgencies(value) {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("readPaginatedAgencies", value);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>