<template>
  <v-card id="agencies" v-if="agencies">
    <v-card-text>
      <v-row class="d-flex align-center">
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            rounded
            dense
            height="1"
            color="#3371E2"
          ></v-text-field>
        </v-col>
        <v-col cols="9">
          <span class="float-right">
            <v-btn
              small
              depressed
              outlined
              color="#8cc73e"
              class="custom-transform-class"
              to="/users-management/add-agency"
              >Add Agency</v-btn
            >
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="filtered_agencies"
      :search="search"
      hide-default-footer
      :server-items-length="pagination.totalItems"
      :options.sync="pagination"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.agency_name="{ item }">
        <h5>{{ item.agency_name }}</h5>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.contact_email="{ item }">
        <h5 class="mt-3">{{ item.contact_email }}</h5>
        <p>{{ item.contact_phone_number }}</p>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.client="{ item }">
        <span v-for="(client, i) in item.client" :key="i">
          <span class="mr-1">{{ client.client_name }},</span>
        </span>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          class="mr-5"
          color="blue"
          icon
          @click="updateAgency(item.id)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn small color="red" icon @click="agency_delete(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-card-actions
      class="d-flex justify-space-between align-center pa-4"
      v-if="agencies"
    >
      <v-btn outlined class="font-transform-inherit" color="#C6C5C5" small>
        <span class="dashboard-blue"
          >Showing page {{ pagination.current }}</span
        >
      </v-btn>
      <v-pagination
        v-model="pagination.page"
        :length="pagination.totalItems"
        circle
        prev-icon="mdi-arrow-left"
        next-icon="mdi-arrow-right"
        color="#3371E2"
        class="custom"
        @input="handlePageChange"
      ></v-pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    search: "",
    pagination: {
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
    },
    filtered_agencies: [],
  }),
  props: {
    headers: Array,
    agencies: Object,
  },
  watch: {
    agencies() {
      this.filtered_agencies = this.agencies.data;
      this.pagination.page = this.agencies.current_page;
      this.pagination.totalItems = this.agencies.last_page;
    },
  },
  methods: {
    updateAgency(id) {
      this.$router.push({
        name: "update-agency",
        params: {
          id: id,
        },
      });
    },
    agency_delete(id) {
      this.$emit("getid", id);
    },
    handlePageChange(value) {
      this.pagination.page = value;
      this.$emit("triggerPageChange", value);
    },
  },
};
</script>

<style scoped>
#agencies {
  margin: 2.5rem 0;
}
</style>
